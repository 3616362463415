import React, { useEffect } from "react";
import { RecipeComponent } from "../components/recipeComponent";
import cx from "classnames";
import * as styles from "./recipeSection.module.scss";
import desertImageSrc from "../../static/images/recipes/gran-desierto-tequila-cocktail.png";
import video from "../../static/video/the-gran-desierto/The_Gran_Desierto.mp4";
import heroVideoMp4 from "../../static/video/the-gran-desierto/the-gran-desierto-tequila.mp4";
import heroVideoWebm from "../../static/video/the-gran-desierto/the-gran-desierto-tequila.webm";

export const RecipeSectionGranDesierto = ({ blogPost }) => {
  useEffect(() => {}, []);

  return (
    <>
      <main>
        <header
          className={cx(
            "relative flex justify-center items-center w-full h-2/6 pt-3 md:pt-4 mt-16 md:mt-24"
          )}
        >
          <video className={cx(
              styles.recipe__heroVideo,
              "mt-1"
            )} playsInline autoPlay loop muted>
	          <source src={heroVideoMp4} type="video/mp4" />
	          <source src={heroVideoWebm} type="video/webm" />
          </video>
        </header> 

        <RecipeComponent
          meta={{
            title: "The Gran Desierto",
            time: "10 Minutes",
            result: "1 Cocktail" 
          }}
          tequilaTypeSlug={ ( blogPost.tequilaType.length > 0 ) ? blogPost.tequilaType[0].slug.current : 'none' }
          procedureParagraphs={[
            'Pour all the ingredients into a shaker with ice, and shake. Then, strain into a rocks glass with fresh ice. Garnish with a dehydrated blood orange slice. Either salt one side of the glass using lime juice as the ad- hesive or add the pinch of salt straight into the shaker with the other ingredients.',
            'Salud.'
          ]}
          ingredients={[
            '1 1/2 oz Celaya Blanco Tequila',
            '3/4 oz Lime Juice',
            '3/4 oz Orange Liqueur',
            '1/4 oz Ginger Syrup',
            'A Pinch of Salt'
          ]}
          toolsNeeded={[]}
          />

        {/* Blogpost */}
        <article className="container py-10 md:py-20 relative">
          <div className="grid md:grid-cols-10">
            <div className="md:col-span-6 md:col-start-3 md:text-center relative">
              <h1 className="font-sans uppercase text-2xl leading-10">How to Upgrade Your Go-to Tequila Cocktail at Home (without Complicating Your Bar Cart)</h1>
              <p className="mt-12">Exceptional does not require more. In fact, one of our favorite ways to make a fine cocktail is with three ingredients. We’ll even take it a step further. Ex- ceptional shouldn’t be fussy. It’s about starting with a wonderful tequila and knowing how to mix ingredients that help it shine on the palate.</p>
              <p className="mt-12">There will always be a place and time for a marg. Or a paloma. Or a straight up pour. But we like to offer some ingredients even tequila enthusiasts may not have considered before. Especially if we’re making cocktails for friends. Through many nights of experimenting, we created The Gran Desierto.</p>
              <p><img className="mt-12 mx-auto w-2/3" src={desertImageSrc} alt="The Gran Desierto Tequila Cocktail" /> </p>
              <h2 className="mt-12 font-sans uppercase text-gold text-xl leading-10">Named after The Gran Desierto de Altar. A region in the Sonoran Desert in northwest Mexico.</h2>
              <p className="mt-12 text-gold">It kisses the southern edges of Arizona and California. And it’s where the Celaya family name became a legacy. It includes the only active erg dune region in North America. Which just means it’s a big area covered by wind-swept sand. No vegetation.</p>
              <p className="mt-12 text-gold">In a way, our Gran Desierto cocktail reminds us of the desert. Its beauty is in its simplicity. It’s about what’s not there just as much as what’s there.</p>
            </div>
          </div>
        </article>

        {/* Video */}
        <aside className="container pb-40 md:px-20">
          <div className="grid md:gap-32 grid-cols-10">
            <div className="col-span-10">
              <div className={cx(styles.responsiveEmbed)}>
                <video width="400" controls>
                  <source src={video} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </aside>
        
      </main>
    </>
  );
};
