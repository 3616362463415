import React, { useEffect } from "react";
// import PortableText from "../components/portableText";
import cx from "classnames";
import { SanityImage } from "../components/sanityImage";
import { Link } from "gatsby";
import BlogIndexQuery from "../static-queries/blogIndexQuery";
import PortableText from "../components/portableText";
import { PostCard } from "../components/postCard";
import C_Logo_Inverted from "../icons/c_logo-inverted";
import { PDPStory } from "../sections/pdpStory";

export const BlogPostSection = ({ blogPost }) => {
  useEffect(() => {}, []);
  
  return (
    <>
      <section className={cx("mt-mnav md:mt-dnav journal")}>
        <div className="sectionHeader fixed top-0 left-0 right-0 w-full flex justify-center items-center w-full justify-center items-center z-0 bg-none px-4 md:px-0">
          {/* <div className="sectionHeaderInner flex justify-center items-center h-full border-b border-dashed border-gold pt-6 md:pt-0 pb-4 md:pb-0">
            <C_Logo_Inverted className="sectionHeaderLogo" />
          </div> */}
        </div>

        <div className="">
          <div>
            {/* <div className="md:pb-6"> */}

            {/* <Link
                to={`/${BlogIndexQuery().slug.current}`}
                className="uppercase text-2xs md:text-xs underline"
              >
                {BlogIndexQuery().blogTitle}
              </Link> */}
            {/* </div> */}
            <div className="grid grid-cols-12 w-full">
              <div className="w-full col-span-12 md:col-span-8">
                <SanityImage
                  className={cx("w-full flush-component")}
                  alt={blogPost.headline}
                  image={blogPost.mainImage}
                  gatsbyImageDataArgs={{ aspectRatio: 1.188 }}
                />
              </div>

              <div className="flex flex-col items-center justify-center w-full col-span-12 md:col-span-4 px-8 py-8 md:py-0">
                <div className="flex justify-center w-full  font-serif text-gold lowercase text-xs">
                  {blogPost.category.title}
                </div>
                <PortableText
                  className="flex justify-center w-full text-2xl font-historical pb-8"
                  blocks={blogPost.title}
                />

                <div className="">
                  <div className="flex justify-center text-center w-full">
                    <h2 className="w-full font-serif text-sm">
                      {blogPost.headline}
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            {blogPost.acknowledgements ? (
              <div className="flex flex-col justify-center items-center bg-black w-full text-white py-20">
                <div>
                  <PortableText
                    className="w-full text-sm text-center font-historical"
                    blocks={blogPost.acknowledgements}
                  />
                </div>
                <div className=" w-full md:w-1/2 font-historical text-base pt-20 px-4 md:px-0">
                  {blogPost.introduction}
                </div>
              </div>
            ) : (
              ""
            )}
            {blogPost.slug.current === "our-process" ? (
              <PDPStory data={blogPost} />
            ) : (
              ""
            )}
            <div>
              {blogPost.articleBody ? (
                <div>
                  {blogPost.articleBody.map((block, key) => {
                    return (
                      <div
                        key={key}
                        className="relative flex flex-col items-center w-full pt-20"
                      >
                        {block.blockQuoteImage ? (
                          <div
                            className={cx(
                              block.blockQuoteImage.alignment === "left"
                                ? "flex-row"
                                : "flex-row-reverse",
                              "flex flex-col md:flex-row items-center w-full my-28"
                            )}
                          >
                            <div className="text-center w-4/5 md:w-1/2 flex items-center border-t border-b border-gold border-dashed md:border-t-0 md:border-b-0 mb-20 md:mb-0 py-5 md:py-0">
                              <div className="font-historical text-lg md:text-2xl px-3 md:px-20">
                                {block.blockQuoteImage.quote}
                              </div>
                            </div>
                            <div className="w-full md:w-1/2">
                              <div
                                style={
                                  block.blockQuoteImage.tiltDirection === "left"
                                    ? {
                                        transform: `rotate(-${
                                          block.blockQuoteImage.tilt
                                        }deg)`
                                      }
                                    : {
                                        transform: `rotate(${
                                          block.blockQuoteImage.tilt
                                        }deg)`
                                      }
                                }
                              >
                                <SanityImage
                                  className={cx(
                                    "w-3/5 md:w-full flush-component"
                                  )}
                                  alt={"Celaya Tequila"}
                                  image={block.blockQuoteImage.image}
                                  gatsbyImageDataArgs={{ aspectRatio: 1.188 }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={cx(
                              block.blockQuote
                                ? "border-t border-b border-dashed border-gold w-4/5 md:border-t-0 md:border-b-0"
                                : "w-full md:w-2/5",
                              "px-3 relative flex flex-col"
                            )}
                          >
                            <PortableText
                              className="w-full text-xl"
                              blocks={block.text}
                            />

                            {block.sideCaption && (
                              <div
                                className={cx(
                                  "post__caption absolute top-1/2 font-historical text-lg",
                                  block.sideCaption.alignment === "left"
                                    ? "-left-80"
                                    : "-right-80"
                                )}
                              >
                                {block.sideCaption.content}
                              </div>
                            )}
                          </div>
                        )}

                        {block.sideImage && (
                          <div
                            style={
                              block.sideImage.tiltDirection === "left"
                                ? {
                                    transform: `rotate(-${
                                      block.sideImage.tilt
                                    }deg)`
                                  }
                                : {
                                    transform: `rotate(${
                                      block.sideImage.tilt
                                    }deg)`
                                  }
                            }
                            className={cx(
                              "absolute top-1/2 ",
                              block.sideImage.alignment === "left"
                                ? "left-0"
                                : "right-0"
                            )}
                          >
                            <SanityImage
                              className={cx("w-full flush-component")}
                              image={block.sideImage.image}
                              alt={"Celaya Tequila Journal Entries"}
                              gatsbyImageDataArgs={{ aspectRatio: 1.188 }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="container">
        <div className="">
          <div className="flex justify-center w-full">
            <div className="w-1/2">
              <PortableText className="pb-20" blocks={blogPost.body1} />
              <SanityImage
                  className={cx("w-full flush-component")}
                  alt={blogPost.headline}
                  image={blogPost.secondaryImage}
                  gatsbyImageDataArgs={{ aspectRatio: 1.5 }}
                />
                <PortableText className="flex w-full justify-start font-serif text-gold lowercase text-xs pt-3 pb-20" blocks={blogPost.secondaryImageCaption} />
                <PortableText className="" blocks={blogPost.body2} />
                <PortableText className="font-serif lowercase pl-8 my-20 border-l border-gold border-dashed text-2xl" blocks={blogPost.blockQuote} />
                <PortableText className="" blocks={blogPost.body3} />

            </div>
            <div className="col-span-2"></div>
          </div>
        </div>
      </section> */}

      <section className="container font-serif text-xs md:text-base pt-12 md:pt-28 mt-2">
        {blogPost.relatedArticles ? (
          <div>
            <div className="md:hidden">
              <div className="flex font-serif lowercase text-base">
                Related Articles
              </div>
              <div className="carousel pl-4">
                {blogPost.relatedArticles.map(post => (
                  <PostCard
                    key={post.id}
                    className="postCard pb-6  h-full"
                    post={post}
                    layout={post.previewLayout.title}
                  />
                ))}
              </div>
            </div>

            <div className="hidden md:block">
              <div className="flex justify-start w-full font-serif lowercase text-xl">
                Related Articles
              </div>
              <div className="relative pt-6 pb-16 md:pt-11 md:pb-7">
                <div className="grid grid-cols-4 w-full">
                  {blogPost.relatedArticles.map(post => (
                    <PostCard
                      key={post.id}
                      className="pb-6 h-full"
                      post={post}
                      layout={post.previewLayout.title}
                    />
                  ))}
                </div>
                <div className="absolute w-px top-0 right-0 h-full bg-offWhite z-10" />
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </section>
    </>
  );
};
