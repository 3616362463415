import React from "react";

export const RecipeComponent = ( { meta, tequilaTypeSlug, procedureParagraphs, ingredients, toolsNeeded } ) => {

    return (
        <>
            <article className="border-b border-dashed border-gold bg-darkBeige">
                <header className="container pt-4 pb-8 md:pt-8 md:pb-10 md:px-20 border-b border-dashed border-gold">
                    <div className="grid grid-cols-12">
                        
                        {/* Meta */}
                        <div className="col-span-12 md:col-span-8">
                            <h4 className="font-serif lowercase text-2xl mb-2">{ meta.title }:</h4>
                            <div className="font-historical uppercase tracking-wider">
                            Time: { meta.time } <span className="px-2">|</span> Yield: { meta.result }
                            </div>
                        </div>

                        {/* Tequila type */}
                        <div className="col-span-12 md:col-span-4 mt-5 md:mt-0">
                            <div>                                
                                <div className="flex items-end mb-1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlSpace="preserve"
                                        viewBox="0 0 27.88 26.59"
                                        className="w-5 inline mr-2"
                                        >
                                        <path d="M0 3.73h21.9v21.9H0z" fill="#C8A677" />
                                        {/* Checkmark */}
                                        { tequilaTypeSlug === 'blanco' && <path
                                            d="M27.38 5.66c.4.98.41 1.39.18 1.49-.14.06-.16.31-.24.45-.06.12-.09.3-.21.32-.37.08-.54.43-.66.84-.21.76-3.26 2.18-3.54 2.38-.44.25-1.8 1.04-1.81 1.44 0 .42-1.2.97-1.28 1.4-.03.17-.16.24-.29.3-.58.25-.27 1.46-.81 1.75-.33.18-.57.46-.85.7-.07.06-.08.17-.17.2-.97.35-1.29 1.37-2.07 1.91-.16.1-.33.21-.5.32-.1.07-.25.1-.29.23-.19.74-.9.96-1.55 1.26-.09.04-.24.02-.23.15.06.71-.66.71-.89 1.16-.07.14-.24.21-.37.3-.1.07-.26.1-.3.23-.15.58-.67.85-.95 1.31-.02.03-.06.04-.06.09.03.77-.75.85-1.44 1.04-.42.11-.78.27-.59.85.04.11.05.22-.04.23-.5.05-.67.36-.96.58-.01.01-.08-.02-.11-.02-.09 0-1.64-1.09-1.97-1.34-.33-.24-4.1-3.4-4.18-3.53-.07-.12-.17-.26-.17-.32.02-.21-.12-.51-.69-1.1-.3-.31-.32-.46-.2-.5.92-.27 1.1-1.13 1.78-1.58.29-.19.38-.54.59-.8 1.07-1.27 2.17-2.5 3.29-3.71.2-.21.45-.37.8-.44.33-.06.56-.22.75-.41.32-.33.57-.73.89-1.07.45-.48.93-.93 1.42-1.36.81-.74 1.65-1.45 2.48-2.17.03-.02.08-.02.08-.08-.03-1.01 1.72-2.13 1.83-2.22.42-.42.81-.89 1.31-1.24.49-.35.82-.88 1.39-1.14.49-.22.81-.66 1.21-1 .06-.05.09-.14.17-.17.84-.31 3.86-2.14 4.23-2.38.08-.06.26.04.53.28.25.23.41.29.65.51.24.22.57.63.78.8.22.18.32.15.49.25.18.11.52.55.83 1.06.36.56 1.43 2.26 1.74 2.75"
                                            fill="#4d4e56" /> }
                                    </svg>{" "}
                                    <span className="text-gold text-sm leading-none">
                                    Blanco
                                    </span>
                                </div>

                                <div className="flex items-end mb-1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlSpace="preserve"
                                        viewBox="0 0 27.88 26.59"
                                        className="w-5 inline mr-2"
                                        >
                                        <path d="M0 3.73h21.9v21.9H0z" fill="#C8A677" />
                                        {/* Checkmark */}
                                        { tequilaTypeSlug === 'reposado' && <path
                                            d="M27.38 5.66c.4.98.41 1.39.18 1.49-.14.06-.16.31-.24.45-.06.12-.09.3-.21.32-.37.08-.54.43-.66.84-.21.76-3.26 2.18-3.54 2.38-.44.25-1.8 1.04-1.81 1.44 0 .42-1.2.97-1.28 1.4-.03.17-.16.24-.29.3-.58.25-.27 1.46-.81 1.75-.33.18-.57.46-.85.7-.07.06-.08.17-.17.2-.97.35-1.29 1.37-2.07 1.91-.16.1-.33.21-.5.32-.1.07-.25.1-.29.23-.19.74-.9.96-1.55 1.26-.09.04-.24.02-.23.15.06.71-.66.71-.89 1.16-.07.14-.24.21-.37.3-.1.07-.26.1-.3.23-.15.58-.67.85-.95 1.31-.02.03-.06.04-.06.09.03.77-.75.85-1.44 1.04-.42.11-.78.27-.59.85.04.11.05.22-.04.23-.5.05-.67.36-.96.58-.01.01-.08-.02-.11-.02-.09 0-1.64-1.09-1.97-1.34-.33-.24-4.1-3.4-4.18-3.53-.07-.12-.17-.26-.17-.32.02-.21-.12-.51-.69-1.1-.3-.31-.32-.46-.2-.5.92-.27 1.1-1.13 1.78-1.58.29-.19.38-.54.59-.8 1.07-1.27 2.17-2.5 3.29-3.71.2-.21.45-.37.8-.44.33-.06.56-.22.75-.41.32-.33.57-.73.89-1.07.45-.48.93-.93 1.42-1.36.81-.74 1.65-1.45 2.48-2.17.03-.02.08-.02.08-.08-.03-1.01 1.72-2.13 1.83-2.22.42-.42.81-.89 1.31-1.24.49-.35.82-.88 1.39-1.14.49-.22.81-.66 1.21-1 .06-.05.09-.14.17-.17.84-.31 3.86-2.14 4.23-2.38.08-.06.26.04.53.28.25.23.41.29.65.51.24.22.57.63.78.8.22.18.32.15.49.25.18.11.52.55.83 1.06.36.56 1.43 2.26 1.74 2.75"
                                            fill="#4d4e56" /> }
                                    </svg>{" "}
                                    <span className="text-gold text-sm leading-none">
                                    Reposado
                                    </span>
                                </div>
                                
                                <div className="flex items-end">
                                <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlSpace="preserve"
                                        viewBox="0 0 27.88 26.59"
                                        className="w-5 inline mr-2"
                                        >
                                        <path d="M0 3.73h21.9v21.9H0z" fill="#C8A677" />
                                        {/* Checkmark */}
                                        { tequilaTypeSlug === 'anejo' && <path
                                            d="M27.38 5.66c.4.98.41 1.39.18 1.49-.14.06-.16.31-.24.45-.06.12-.09.3-.21.32-.37.08-.54.43-.66.84-.21.76-3.26 2.18-3.54 2.38-.44.25-1.8 1.04-1.81 1.44 0 .42-1.2.97-1.28 1.4-.03.17-.16.24-.29.3-.58.25-.27 1.46-.81 1.75-.33.18-.57.46-.85.7-.07.06-.08.17-.17.2-.97.35-1.29 1.37-2.07 1.91-.16.1-.33.21-.5.32-.1.07-.25.1-.29.23-.19.74-.9.96-1.55 1.26-.09.04-.24.02-.23.15.06.71-.66.71-.89 1.16-.07.14-.24.21-.37.3-.1.07-.26.1-.3.23-.15.58-.67.85-.95 1.31-.02.03-.06.04-.06.09.03.77-.75.85-1.44 1.04-.42.11-.78.27-.59.85.04.11.05.22-.04.23-.5.05-.67.36-.96.58-.01.01-.08-.02-.11-.02-.09 0-1.64-1.09-1.97-1.34-.33-.24-4.1-3.4-4.18-3.53-.07-.12-.17-.26-.17-.32.02-.21-.12-.51-.69-1.1-.3-.31-.32-.46-.2-.5.92-.27 1.1-1.13 1.78-1.58.29-.19.38-.54.59-.8 1.07-1.27 2.17-2.5 3.29-3.71.2-.21.45-.37.8-.44.33-.06.56-.22.75-.41.32-.33.57-.73.89-1.07.45-.48.93-.93 1.42-1.36.81-.74 1.65-1.45 2.48-2.17.03-.02.08-.02.08-.08-.03-1.01 1.72-2.13 1.83-2.22.42-.42.81-.89 1.31-1.24.49-.35.82-.88 1.39-1.14.49-.22.81-.66 1.21-1 .06-.05.09-.14.17-.17.84-.31 3.86-2.14 4.23-2.38.08-.06.26.04.53.28.25.23.41.29.65.51.24.22.57.63.78.8.22.18.32.15.49.25.18.11.52.55.83 1.06.36.56 1.43 2.26 1.74 2.75"
                                            fill="#4d4e56" /> }
                                    </svg>{" "}
                                    <span className="text-gold text-sm leading-none">Añejo</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container pt-10 pb-20 md:px-20">
                    <div className="grid md:gap-32 md:grid-cols-10">
                        
                        {/* Ingredients */}
                        <div className="md:col-span-4 font-historical text-xs md:order-2">
                            <p className="lowercase font-serif text-xs">Ingredients:</p>
                            { ingredients.map( (ingredient) => (
                                <p key={ingredient}>{ingredient}</p>
                            ) ) }
                             
                            { toolsNeeded.length > 0 &&
                                <p className="lowercase font-serif text-xs mt-8">Tools Needed:</p>
                            }

                            { toolsNeeded.map( (tool) => {
                                return( <p key={tool}>{tool}</p> )
                            } ) }
                        </div>

                        {/* Procedure */}
                        <div className="md:col-span-6 md:order-1 md:mt-0">
                            <p className="lowercase font-serif text-xs">Procedure:</p>
                            <p>
                                Pour all the ingredients into a shaker with ice, and shake. Then, strain into a coup glass, or whichever type of glass you prefer. Garnish with a dehydrated dragon fruit, and enjoy.
                            </p>
                        </div>
                    </div>
                </div>
            </article>
        </>
    ); 
};