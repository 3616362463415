import React, { useEffect } from "react";
// import PortableText from "../components/portableText";
import cx from "classnames";
import { SanityImage } from "../components/sanityImage";
import { Link } from "gatsby";
import BlogIndexQuery from "../static-queries/blogIndexQuery";
import PortableText from "../components/portableText";
import { PostCard } from "../components/postCard";
import C_Logo_Inverted from "../icons/c_logo-inverted";
import { PDPStory } from "../sections/pdpStory";
import * as styles from "./recipeSection.module.scss";
import lime from "../../static/images/lime.svg";

export const RecipeSection = ({ blogPost }) => {
  useEffect(() => {}, []);

  return (
    <>
      <section
        className={cx(
          "relative flex justify-center items-center w-full h-2/6 pt-3 md:pt-4 mt-16 md:mt-24"
        )}
      >
        <SanityImage
          className={cx(
            styles.recipe__heroContainer,
            "top-0 left-0 h-80 w-full object-cover bg-black absolute z-0 object-right md:object-center mt-1"
          )}
          alt={blogPost.headline}
          image={blogPost.mainImage}
          gatsbyImageDataArgs={{ aspectRatio: 1.188 }}
        />
      </section>

      <div className="border-b border-dashed border-gold">
        <div className="container pt-4 pb-8 md:pt-8 md:pb-10 md:px-20">
          <h4 className="font-serif lowercase text-2xl mb-2">
            Frecia&apos;s Margarita
          </h4>
          <div className="font-historical uppercase tracking-wider">
            Time: 10 Minutes
            <br />
            Yield: 1 Cocktail
          </div>
        </div>
      </div>

      <div className="container pt-10 pb-40 md:px-20">
        <div className="grid md:gap-32 md:grid-cols-10">
          <div className="md:col-span-4 font-historical text-xs md:order-2">
            <p className="lowercase font-serif text-xs">Ingredients:</p>
            <p>2 oz Blanco (Celaya) Tequila</p>
            <p>1 oz Fresh Lemon Juice</p>
            <p>1 oz Light Agave Syrup</p>
            <p>1/2 small Hamlin or Valencia orange</p>
            <p>1 oz Club Soda</p>
            <p>3/4 cup (3 to 4) ice cubes</p>
            <p>Kosher Salt</p>
            <p className="lowercase font-serif text-xs mt-8">tools needed:</p>
            <p>Saucer for Salt</p>
            <p>Measuring Cup in Ounces</p>
            <p>Cocktail Shaker / Strainer</p>

            <div className="mt-10 hidden md:block">
              <img className="w-1/2" src={lime} alt="" />
            </div>
          </div>
          <div className="md:col-span-6 md:order-1 mt-8 md:mt-0">
            <p className="font-historicalItalic">
              The key to a good Margarita isn&apos;t new invention, but good
              ingredients and more importantly ... good tequila. For that, we
              have you covered! We&apos;ve named this Margarita after our
              beloved Abuela, Frecia Celaya -- a woman as true and classic as
              they come.
            </p>
            <p className="lowercase font-serif text-xs mt-8">procedure:</p>
            <p>
              Add tequila into a small saucer and then add kosher salt to a
              separate small saucer. Dip the rim of your glass into the tequila
              and then dip it into the salt.
            </p>
            <p className="mt-8">
              Cut your lime in half and then cut a thin slice for garnish. Juice
              lime and orange into a measuring cup until you have 2 fluid ounces
              of juice.
            </p>
            <p className="mt-8">
              Pour juice into a cocktail shaker, add the agave and tequila. Add
              the ice to the shaker, cover, and shake for 30 seconds. Strain the
              mixture through a cocktail strainer into your glass and add your
              sliced orange.
            </p>
            <p className="mt-8">Enjoy!</p>
            <div className="text-right md:hidden">
              <img className="w-1/2 inline-block pr-8" src={lime} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
