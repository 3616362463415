import React, { useEffect } from "react";
import { RecipeComponent } from "../components/recipeComponent";
import cx from "classnames";
import * as styles from "./recipeSection.module.scss";
import albertoCelayaPortraitImageSrc from "../../static/images/recipes/sonoyta-blue-tequila-cocktail.png";
import video from "../../static/video/sonoyta-blue/The_Sonyota_Blue.mp4";
import heroVideoMp4 from "../../static/video/sonoyta-blue/sonoyta-blue-header-video.mp4";
import heroVideoWebm from "../../static/video/sonoyta-blue/sonoyta-blue-header-video.webm";

export const RecipeSectionSonoytaBlue = ({ blogPost }) => {
  useEffect(() => {}, []);

  return (
    <>
      <main>
        <header
          className={cx(
            "relative flex justify-center items-center w-full h-2/6 pt-3 md:pt-4 mt-16 md:mt-24"
          )}
        >
          <video className={cx(
              styles.recipe__heroVideo,
              "mt-1"
            )} playsInline autoPlay loop muted>
	          <source src={heroVideoMp4} type="video/mp4" />
	          <source src={heroVideoWebm} type="video/webm" />
          </video>
        </header> 

        <RecipeComponent
          meta={{
            title: "The Sonoyta Blue",
            time: "10 Minutes",
            result: "1 Cocktail" 
          }}
          tequilaTypeSlug={ ( blogPost.tequilaType.length > 0 ) ? blogPost.tequilaType[0].slug.current : 'none' }
          procedureParagraphs={[
            'Pour all the ingredients into a shaker with ice, and shake. Then, strain into a coup glass, or whichever type of glass you prefer. Garnish with a dehydrated dragon fruit, and enjoy.'
          ]}
          ingredients={[
            '2 oz Celaya Reposado Tequila',
            '3/4 oz Lemon Juice',
            '1/4 oz Aperol',
            '1/4 oz Yellow Chartreuse',
            'A Pinch of Smoked Salt'
          ]}
          toolsNeeded={[
            'Measuring Cup in Ounces',
            'Cocktail Shaker / Strainer'
          ]}
          />

        {/* Blogpost */}
        <article className="container py-10 md:py-20 relative">
          <div className="grid md:grid-cols-10">
            <div className="md:col-span-6 md:col-start-3 md:text-center relative">
              <h1 className="font-sans uppercase text-2xl leading-10">
                  A Brief History of the Sonoyta Blue, the Tequila and Aperol Cocktail Everyone Should Try.
              </h1>
              <p className="mt-12">
                Nestled below Lukeville, Arizona, Sonoyta is a town in Mexico you could throw a rock to the United States from. For a long time, the border was a hotspot for imports and exports. But during the 80s and 90s, business dwindled due to changes in customs laws in Mexico. During that time, Mr. Julian Hayden, Mr. Ronald Ives, Mr. Paul Ez, et al. would sit on Alberto Celaya’s patio, drinking tequila, discussing the latest hap- penings. Alberto had become an influential, respected leader in his town of Sonoyta. And these scientists, governors, etc. would all frequent the Celaya household, calling him “Don Alberto” as a sign of respect and admiration.
              </p>
              <p><img className="mt-12 mx-auto w-2/3" src={albertoCelayaPortraitImageSrc} alt="Alberto Celaya — Tequila Maker" /> </p>
              <p className="mt-12 font-historicalItalic">
                  Alberto Celaya was also the great, great uncle of Ryan and Matt, who make Celaya Tequila today.
              </p>
              <p className="mt-12">
                  While family lore corroborates these stories, there’s actually official docu- mentation from the Journal of the Southwest, Vol. 49, No. 3, Science on Desert and Lava: A Pinacate. In it, a letter from Julian to Aunt Aurora, who lived with Alberto Celaya at the time. Julian Hayden wrote this note and included a copy of Ronald Ives’ book, Jose Velasquez:
              </p>
              <div className="mt-12 p-1 border border-gold">
                  <div className="p-12 border border-gold">
                      <p className="text-xs">Dear Aurora and all the family,</p>
                      <p className="mt-12 text-xs">I send herewith Ronald Ives' book just published, dedicated to Don Alberto, Ygnacio Quiroz and Father Oblasser. I wish Ronald had lived to present this to you and your family himself, but he didn't, so I do. Alberto Jr. called a while back to tell me of Eddie's death. I'm sorry he's gone, but with cancer, it's God's mercy. He leaves good memories, and I wish I knew him.</p>
                      <p className="mt-12 text-xs">On the other hand, I hear Patricia will make Lola a grandmother again, and you a great-aunt. That's the way the smart comes down, isn't it? And Alberto's father and Patricia's father would be so proud of the first son of the new one to come.</p>
                      <p className="mt-12 text-xs">Here no changes especially. I had a lens implant in one eye, which I've been blind in for a long time. It is doing well, though I won't be able to be in Pinacate for several months yet. I stopped by and left notes, as you know, but have missed you, to my disappointment. I hope you are well and cheerful and busy, and I know Lola and Patricia are, Jesus and Alberto Jr.</p>
                      <p className="mt-12 text-xs">If and when I come to Sonoyta again, I'll stop by to visit with you. My love to all, my long-time friends.</p>
                      <p className="mt-12 text-xs font-historicalItalic">Julian.</p>
                      <p className="italic text-xs font-historicalItalic">June 20, 1984</p>
                  </div>
              </div>
              <blockquote className="mt-12 font-sans uppercase text-gold text-xl leading-10">
                  In honor of our abuelo, Alberto Celaya and the nights he spent drinking tequila with his friends, we have created the Sonoyta Blue.
              </blockquote>
            </div>
          </div>
        </article>

        {/* Video */}
        <aside className="container pb-40 md:px-20">
          <div className="grid md:gap-32 grid-cols-10">
            <div className="col-span-10">
              <div className={cx(styles.responsiveEmbed)}>
                <video width="400" controls>
                  <source src={video} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </aside>
        
      </main>
    </>
  );
};
