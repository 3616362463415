import React from "react";
import cx from "classnames";
import { SanityImage } from "../components/sanityImage";
import { Link } from "gatsby";
import BlogIndexQuery from "../static-queries/blogIndexQuery";
import PortableText from "../components/portableText";
import { PostCard } from "../components/postCard";
import C_Logo_Inverted from "../icons/c_logo-inverted";
import { PDPStory } from "../sections/pdpStory";
import { BlogPostSection } from "../sections/blogPostSection";
import { RecipeSection } from "../sections/recipeSection";
import { RecipeSection1855 } from "../sections/recipeSection1855";
import { RecipeSectionSonoytaBlue } from "../sections/recipeSectionSonoytaBlue";
import { RecipeSectionGranDesierto } from "../sections/recipeSectionGranDesierto";

const BlogPost = ({ pageContext }) => {
  const { blogPost } = pageContext;

  return (
    <>
      {blogPost.slug.current === "frecias-margarita" ? (
        <RecipeSection blogPost={blogPost} />
      ) : blogPost.slug.current ===
        "the-three-ingredient-tequila-cocktail-every-enthusiast-should-know" ? (
        <RecipeSection1855 blogPost={blogPost} />
      ) : blogPost.slug.current ===
        "history-of-the-sonoyta-blue-tequila-and-aperol-cocktail" ? (
        <RecipeSectionSonoytaBlue blogPost={blogPost} />
      ) : blogPost.slug.current ===
        "upgrade-your-go-to-tequila-cocktail" ? (
        <RecipeSectionGranDesierto blogPost={blogPost} />
      ): (
        <BlogPostSection blogPost={blogPost} />
      )}
    </>
  );
};

export default BlogPost;
